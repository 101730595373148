/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  background: #eeeeee;
  color: #444;
  font-family: 'Roboto', sans-serif;
}

a {
  color: #50d8af;
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  color: #51d8af;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #50d8af;
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

.back-to-top:focus {
  background: #50d8af;
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #0c2e8a;
  color: #fff;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #555;
}

@media (max-width: 768px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */

#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(52, 59, 64, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #50d8af;
}

#mobile-nav ul .menu-item-active {
  color: #50d8af;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(52, 59, 64, 0.9);
  display: none;
}

/* Mobile Nav body classes */

body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/

/* Sections Header
--------------------------------*/

.section-header {
  margin-bottom: 30px;
}

.section-header h2 {
  font-size: 32px;
  color: #0c2e8a;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
}

.section-header h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #50d8af;
  bottom: 0;
  left: 0;
}

.section-header p {
  padding: 0;
  margin: 0;
}

/* Services Section -------------------------------- */

.section {
  padding: 30px;
}

.section .box {
  padding: 20px;
  box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
  background: #fff;
  transition: 0.4s;
  height: 150px;
}

.section .box:hover {
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
}

.section .box .icon {
  float: left;
}

.section .box .icon i {
  /*color: #444;*/
  font-size: 54px;
  transition: 0.5s;
  line-height: 0;
  margin-top: 34px;
}

/*.section .box .icon i:before {
  background: #0c2e8a;
  background: linear-gradient(45deg, #50d8af 0%, #a3ebd5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}*/

.section .box h4 {
  margin-left: 80px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.section .box h4 a {
  color: #444;
}

.section .box p {
  font-size: 14px;
  margin-left: 80px;
  margin-bottom: 0;
  line-height: 24px;
}

@media (max-width: 767px) {
  .section .box {
    margin-bottom: 20px;
    height: 250px;
  }
  .section .box .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }
  .section .box h4, .section .box p {
    margin-left: 0;
    text-align: center;
  }
}
