.ui-g-12 {
  margin: auto;
}

@media screen and (max-width: 1439px) {
  .mdb-card-header {
    .p-field {
      span.p-float-label {
        label {
          letter-spacing: -0.8px;
        }
      }
    }
    .date-field {
      min-width: 309px;
    }
    .user-date-field {
      min-width: 98px;
    }
    .status-field {
      min-width: 93px;
    }

  }
}